<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="reporter-list-view" class="data-list-container">
    <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
    <vs-table ref="table" pagination search :max-items="renderMethod.itemsPerPage" :data="items">
    <!-- <vs-table ref="table" :max-items="renderMethod.itemsPerPage" :data="items"> -->
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div style="margin-right: 15px; margin-bottom: 10px; display: flex;">
          <vs-button type="border" color="warning" @click="xlsxDown()">엑셀 다운로드</vs-button>

          <datepicker type="date" range v-model="dateRange2" lang="kr" style="width: 200px;" />
          <!-- <date-range-picker 
              v-model="dateRange" 
              :locale-data="locale"
              :opens="opens"
              :ranges="ranges"
            >
            <div id="dates" slot="range" ></div>
          </date-range-picker> -->
          <v-select style="width: 150px" placeholder="카테고리" label="name" :reduce="name => name.code" v-model="category1"
            :options="categories1"/>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 mb-4">
          <div
            class="p-4 border border-solid border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span
              class="mr-2"
            >{{ ((currentx - 1) * renderMethod.itemsPerPage) + 1 }} - {{ numOfItems >= renderMethod.itemsPerPage ? (currentx * renderMethod.itemsPerPage) : numOfItems }} of {{ numOfItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="changeItemPerPage(4)">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(10)">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(15)">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(30)">
              <span>30</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(50)">
              <span>50</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(100)">
              <span>100</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="title">제목</vs-th>
        <vs-th sort-key="viewed">조회수</vs-th>
        <vs-th sort-key="publishedTime">날짜</vs-th>
        <vs-th sort-key="categoryName">분류</vs-th>
        <vs-th sort-key="writerName">작성자</vs-th>
      </template>
      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td><div @click="showNews(tr.id)" style="font-weight: bold">{{ tr.title }}</div></vs-td>
            <vs-td><div style="color: blue">{{ tr.viewed }}</div></vs-td>
            <vs-td><div>{{ tr.publishedTime }}</div></vs-td>
            <vs-td><div>{{ tr.categoryName }}</div></vs-td>
            <vs-td><div>{{ tr.writerName }}</div></vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <!-- <div>
        <vs-pagination :total="numOfPages" v-model="currentx"></vs-pagination>
    </div> -->
    <div id="div-with-loading" class="news-detail-form vs-con-loading__container">
      <vs-popup ref="popup-newsDetail" classContent="popup-newsDetail" title="기사 상세" :active.sync="newsDetailModalActive">
        <div class="items-center">
          <div class="news-detail" id="view_con" style="margin: 20px 0px 0px;" v-html="newsDetail.data"></div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/lib/vue-daterange-picker.min.css';
import moment from 'moment';
import * as xlsx from 'xlsx';


export default {
  components: {
    // AddNewDataSidebar
    DateRangePicker,
  },
  data() {
    return {
      items: [],
      currentx: 1,
      numOfItems: 0,
      numOfPages: 0,
      newsDetail: '',
      newsDetailModalActive: false,

      categories1: [],
      category1: null,
      dateRange: { // used for v-model prop
          startDate: moment(),
          endDate: moment(),
      },
      dateRange2: [new Date(), new Date()],
      renderMethod: {
        itemsPerPage: 20,
      },
      ranges: { //default value for ranges object (if you set this to false ranges will no be rendered)
          '오늘': [moment(), moment()],
          '1주일': [moment().subtract(1, 'week'), moment()],
          '한달': [moment().subtract(1, 'month'), moment()],
          '이번 달': [moment().startOf('month'), moment().endOf('month')],
          '지난 달': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      },
      opens: "center",//which way the picker opens, default "center", can be "left"/"right"
      locale: {
          direction: 'ltr', //direction of text
          format: 'YYYY-MM-DD', //fomart of the dates displayed
          separator: ' - ', //separator between the two ranges
          applyLabel: '선택',
          cancelLabel: '취소',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          "daysOfWeek": [
              "일",
              "월",
              "화",
              "수",
              "목",
              "금",
              "토"
          ],
          "monthNames": [
              "1월",
              "2월",
              "3월",
              "4월",
              "5월",
              "6월",
              "7월",
              "8월",
              "9월",
              "10월",
              "11월",
              "12월"
          ],
          firstDay: 0, //ISO first day of week - see moment documenations for details
          showISOWeekNumbers: true //show week numbers on each row of the calendar
      },
      isMounted: false
    };
  },
  beforeRouteLeave (to, from, next) {
    this.newsDetailModalActive = false;
    setTimeout(() => { next(); }, 100);
  },
  watch: {
    
    dateRange2: function() {
      
      this.searchStatisticsDesc();
    },
    currentx: function(){
      this.searchStatisticsDesc();
    },
    category1: function(){
      this.searchStatisticsDesc();
    }
  },
  // computed: {
  //   currentPage() {
  //     if (this.isMounted) {
  //       return this.$refs.table.currentx;
  //     }
  //     return 0;
  //   }
  // },
  methods: {
    detect() {

      console.log('detect')
    },
    changeItemPerPage(pageNum) {
      this.renderMethod.itemsPerPage = pageNum;
      // this.searchStatisticsDesc();
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
    xlsxDown(){
      const workBook = xlsx.utils.book_new()
      const workSheet = xlsx.utils.json_to_sheet(this.items)
      xlsx.utils.book_append_sheet(workBook, workSheet, 'result')
      xlsx.writeFile(workBook, `View_${moment(this.dateRange2[0]).format('YYYY-MM-DD')}_${moment(this.dateRange2[1]).format('YYYY-MM-DD')}.xlsx`)
    },
    
    showNews(newsId) {
      this.newsDetailModalActive = true;
      this.newsDetail = '';
      const thisIns = this;
      this.$vs.loading({
        scale: 1.5
      });
      this.$http.post('/api/getSingleNews/detail', {id: newsId})
        .then(function (response) {
          console.log(response.data);
          thisIns.newsDetail = response.data;
          thisIns.$vs.loading.close();
        })
        .catch(function (error) {
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },

    searchStatisticsDesc() {

      const thisIns = this

      var searchVar = {
        startDate: moment(this.dateRange2[0]).format('YYYY-MM-DD'),
        endDate: moment(this.dateRange2[1]).add(1, 'day').format('YYYY-MM-DD'),
        // startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
        // endDate: moment(this.dateRange.endDate).add(1, 'day').format('YYYY-MM-DD')
        startRow: (this.currentx - 1) * this.renderMethod.itemsPerPage,
        itemsPerPage: this.renderMethod.itemsPerPage,
        category1: this.category1 ? this.category1 : null,
      }

      this.$vs.loading({
        scale: 1.5
      });

      this.$http
      .post("/api/statistics/desc", searchVar)
      .then(function(response) {

        thisIns.$vs.loading.close()
        console.log(response)
        thisIns.items = response.data
        thisIns.numOfItems = thisIns.items.length
        thisIns.currentx = 1;
      //   return thisIns.$http.post("/api/statistics/desc/count", searchVar)
      // })
      // .then(function(response){
      //   console.log(response)
      //   thisIns.numOfItems = response.data;
      //   thisIns.numOfPages = Math.ceil(thisIns.numOfItems / thisIns.renderMethod.itemsPerPage);
      })
      .catch(function(error) {

        thisIns.$vs.loading.close()

        var errorText = error.toString();
        if(errorText.includes('401')){
          alert('로그인 해주세요!')
          console.log(thisIns.$router)
          thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
        }
      });
    },
  },
  created() {
    this.categories1 = [{code: '', name: '전체'}].concat(this.$store.state.categories1);
    this.searchStatisticsDesc();
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/custom-style/vs-table-reporter.scss";
@import "@/assets/scss/custom-style/vs-table-newslist.scss";
</style>